//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


export default {
  name: 'camItem',
  props: ['resource', 'index', 'minimal', 'ratio'],
  data() {
    return {
      hover: false,
      line_complete: false,
    };
  },
  methods: {
    taphandler() {
      window.open('https://webcam.meidenvanholland.nl/profile/' + this.resource.modelnaam, '_blank');
    },
    endHandler() {
      if (!this.line_complete) {
        this.cancelAllPreviews();
      }
    },
    hoverHandler(todo) {
      if (this.resource.coverVideo) {
        this.hover = todo;
      }
    },
    touchHandler() {
      this.cancelAllPreviews();
      if (this.resource.coverVideo) {
        this.startPreview();
      }
    },
    startPreview() {
      this.hover = true;
      setTimeout(() => {
        var element = document.getElementById('video_' + this.resource.modelnaam);
        if (element) {
          element.play();
          element.classList.add('active');
          this.line_complete = true;
        }
      }, 500);
    },
    cancelAllPreviews() {
      document.querySelectorAll('.preview').forEach((element) => {
        if (element && element.modelnaam != 'video_' + this.resource.modelnaam) {
          element.autoplay = false;
          element.load();
          element.classList.remove('active');
        }
      });
      document.querySelectorAll('.line_animation').forEach((element) => {
        if (element && element.modelnaam != 'line_' + this.resource.modelnaam) {
          element.classList.remove('active');
        }
      });
      this.hover = false;
      this.line_complete = false;
    },
  }
}
