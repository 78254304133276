//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'galleryDummy',
  props: ['type', 'ratio', 'orientation', 'count', 'getLoadState', 'minimal', 'relatedItem'],
}
