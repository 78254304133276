//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState } from 'vuex'
import camItem from '~/components/content/cam/cam.vue';
import dummyItem from '~/components/content/dummy/gallery_dummy.vue'

export default {
    fetchOnServer: false,
    async fetch() {
        let query = {
            include: 'images:types(cover):limit(1|0),products,categories',
            page: 1,
            sort: {
                published_at: 'DESC'
            },
            filters: {
                geslacht: 'v',
                leeftijd: '',
                uiterlijk: '',
                haar: '',
                figuur: '',
                taal: 'NL',
                orderby: 'waarderingdesc',
                limit: 12,
                page: 1,
            }
        };
        await this.$store.dispatch('camStore/getCams', query)
    },
    data() {
        return {
            endReached: false,
            grid_type: 'cams',
            content_type: 'cam_item',
            direction: 'horizontal'
        };
    },
    components: {
        camItem,
        dummyItem
    },
    computed: {
        ...mapState({
            cams: ({ camStore: {cams} }) => cams,
            loadState: ({ loadstateStore: {loadState} }) => loadState
        }),
    },
    mounted() {
        this.$refs['slider_' + this.content_type].scrollLeft = 0;
    },
    methods: {
        scrollLeft(content_type) {
            const slider = this.$refs[`slider_${content_type}`];
            const item = this.$refs[`item_${content_type}_0`];
            const itemWidth = item[0].$el.clientWidth;
            const screenWidth = window.innerWidth;
            const scrollAmount =
            Math.floor(slider.scrollLeft / itemWidth - screenWidth / itemWidth) *
            itemWidth;
            if (this.endReached) {
                slider.scrollTo({
                    left: 0,
                    behavior: 'smooth',
                });
                this.endReached = false;
            } else {
                slider.scrollTo({
                    left: scrollAmount,
                    behavior: 'smooth',
                });
            }
        },
        scrollRight(content_type) {
            const slider = this.$refs[`slider_${content_type}`];
            const item = this.$refs[`item_${content_type}_0`];
            const itemWidth = item[0].$el.clientWidth;
            const screenWidth = window.innerWidth;
            const maxScrollLeft = slider.scrollWidth - slider.clientWidth;
            const currentScrollLeft = slider.scrollLeft;
            let scrollAmount;
            if (currentScrollLeft + screenWidth >= slider.scrollWidth) {
                scrollAmount = 0;
            } else {
                scrollAmount = Math.round(currentScrollLeft / itemWidth + screenWidth / itemWidth) * itemWidth;
            }
            slider.scrollTo({
                left: scrollAmount,
                behavior: 'smooth',
            });
        },
        showScrollButtons(slider) {
            const arrowLeft = this.$refs[`arrowleft_${this.content_type}`];
            const arrowRight = this.$refs[`arrowright_${this.content_type}`];
            if (slider.scrollLeft === 0) {
                arrowLeft.style.display = 'none';
            } else {
                arrowLeft.style.display = 'flex';
            }
            // if (slider.scrollLeft + slider.clientWidth >= slider.scrollWidth && !this.endReached) {
            //  arrowRight.style.display = 'none';
            // } else {
            //  arrowRight.style.display = 'flex';
            // }
        },
        onScroll() {
            if (!this.isMobile) {
                this.$nextTick(() => {
                    const slider = this.$refs[`slider_${this.content_type}`];
                    this.showScrollButtons(slider);
                });
            }
        }
    }
}
